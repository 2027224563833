import React from "react";

const BenefitsTable = () => {
  return (
    <div className="list-main-screen-wrapper me-1">
        <div className="list-main-screen-container">
        <ul className="custom-bullets">
        <li>ביטוח אחריות מקצועית מסובסד. ערך כספי - 500 ש”ח לשנה.</li>
        <li>סיבסוד 30 ש״ח לתוכנת שורנס.</li>
        <li>
          50% השתתפות ב-10 מסלקות לחודש (באמצעות המסלקה הכלכלית של שווה). ערך
          כספי - 1,080 ש"ח לשנה.
        </li>

        <li>כנס באילת עלות 2000 ש”ח במקום 3500 ש”ח.</li>
        <li>נסיעה מקצועית לחו”ל עלות 2000 ש”ח במקום 4000 ש”ח.</li>
        <li>כנסים נבחרים.</li>
        <li>קורסים/הדרכות.</li>
        <li>הכשרה במכללת האיגוד.</li>
        <li>שירותי פיתוח עסקי.</li>
        <li>כװרת מומחים.</li>
        <li>יעוץ וגיבױ משפטי - עו”ד ג’ון גבע.</li>
        <li>יעוץ תדמית.</li>
        <li>שיװק דיגיטלי ונוכחות ברשת בעלות מסובסדת.</li>
        <li>כרטיסי ביקור/דיגיטלײם של איגוד.</li>
      </ul>
      <div className="benefits-image-container-mobile">
          <img
            className="main-image3-mobile"
            src="/images/main-screen-image2.jpg"
            alt=""
          />
        </div>
        </div>
   
    </div>
  );
};

export default BenefitsTable;
