import React from "react";
import Header from "../components/Header";
import { Col, Row } from "react-bootstrap";
import "../main-screen.css";
import Footer from "../components/Footer";
import BenefitsTable from "../components/BenefitsTable";
import FooterDetails from "../components/FooterDetails";

const MainScreen = () => {
  document.querySelector("body").style.background = "none";

  const renderDetailsSection = () => {
    return (
      <div className="details-section-container">
        <div className="details-header">
          <div className="details-header-triangle-container">
            <img
              className="triangle-top"
              src="/images/gold-triangle-top.png"
              alt="triangle"
            />
          </div>
          <div className="logo-container">
            <img
              className="logo-image"
              src="/images/logo-ifo.png"
              alt="Logo ifo"
            />
          </div>
        </div>
        <div className="details-section">
          <div className="details-image">
            <img
              width={"100%"}
              className="main-image1"
              src="/images/main-screen-image3.jpg"
              alt=""
            />
          </div>
          <div className="details-text-container mx-1">
            <p className="details-text">
              איגוד מנהלי הפמילי אופיס בישראל הוא גוף מקצועי ואתי המאגד את מנהלי
              הפמילי אופיס בפריסה ארצית. מטרת העל של האיגוד הינה הנגשת שרותי
              הפמילי אופיס בכל בית בישראל. כמו כן האיגוד שם לעצמו מטרה למסד את
              פעילות הפמילי אופיס ולהפוך לגוף בעל יכולות מקצועיות והשפעה בדרגים
              הרגולטורים השונים, כל זאת לביסוס מעמדם של חברי האיגוד. חברי האיגוד
              יקבלו הדרכה מקיפה מטובי המקצוענים בענף בהיקף של 10 שעות מקצועיות.
              בסיום ההכשרה המקצועית יוענקו תעודות ״מנהל פמילי אופיס חבר באיגוד״.
            </p>
            <div className="details-footer">
              <div className="details-footer-triangle-container">
                <img
                  className="triangle-bottom"
                  src="/images/gold-triangle-bottom.png"
                  alt="triangle"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderContactButtons = () => {
    return (
      <div className="contact-buttons-wrapper">
        <Row className="align-items-center justify-content-center d-flex">
          <Col md={4} xs={5}>
            <a className="btn submit-button-form" href="/contact">
              צרו קשר עכשיו
            </a>
          </Col>
          <Col className="d-flex justify-content-center" md={1} xs={1}>
            <h4>או</h4>
          </Col>
          <Col md={4} xs={5}>
            <a
              className="btn submit-button-whatsapp px-0"
              href="https://api.whatsapp.com/send/?phone=%2B972528788214&text&app_absent=0"
            >
              {" "}
              שלחו הודעת ווצאפ
            </a>
          </Col>
        </Row>
      </div>
    );
  };

  const renderBenefitsSection = () => {
    return (
      <div className="w-100 mt-3 ">
        <BenefitsTable />
        <div className="benefits-image-container-desktop">
          <img
            className="main-image3"
            src="/images/main-screen-image2.jpg"
            alt=""
          />
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="bg-white pt-4">
        <div className="main-screen-footer">
          <FooterDetails />
          <Footer />
        </div>
      </div>
    );
  };

  return (
    <div style={{ paddingTop: "55px" }}>
      <div className="main-screen">
        <Header />
        {renderDetailsSection()}
        {renderContactButtons()}
        {renderBenefitsSection()}
        {renderFooter()}
      </div>
    </div>
  );
};

export default MainScreen;
