import React from 'react'

const HorizontalRule = () => {
  return (
    <div className='px-3 d-flex justify-content-center'>
        <hr className='border-3' style={{borderColor:'white', width:'35%'}}/>

    </div>
  )
}

export default HorizontalRule