import React from "react";
import HorizontalRule from "./HorizontalRule";

const FooterDetails = () => {
  return (
    <div>
      <p>
        לפרטים: רחלי לנקרי אחראית אדמיניסטרציה ותפעול
        <br /> 052-878-8214 <br />
        או אל זהבה לשד מנכ”לית האיגוד
        <br /> 054-488-2658
      </p>
      <HorizontalRule />

      <div className="d-flex justify-content-center">
        <img width={"40px"} src="/images/gold-shape.png" alt="gold-shape" />
        <h5 className="text-white align-content-center pt-2">
          דמי החבר בארגון הינם 50 ש״ח חודשי
        </h5>
      </div>
      <div className="d-flex justify-content-center">
        <a href="https://tlpinscoil.activetrail.biz/ifo-הרשמה-לסוכנים" className="text-decoration-none text-black" >            
        <div className="w-40 px-2 bg-white d-flex justify-content-center rounded-1 gap-1 pt-1 cursor-pointer">
            <img src="/images/arrow-icon.jpg" alt="arrow-icon" width={'35px'}/>
          <h3>להרשמה לחצו כאן</h3>
        </div>
        </a>
      </div>
    </div>
  );
};

export default FooterDetails;
